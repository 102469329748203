<template>
  <div class="pa-10">
    <title-page icon="ico-charging-point">
      {{ $tc("operator-group.operator-group", 2) }}
    </title-page>


    <v-row class="mt-10">
      <v-col>
        <search-field
          :placeholder="$t('operator-group.search-placeholder')"
          v-model="filters.searchText"
          clearable
        ></search-field>
      </v-col>
      <v-col cols="auto">
        <v-select
            :label="$t('role')"
            v-model="filters.role"
            :items="['CPO', 'eMSP']"
            clearable
        ></v-select>
      </v-col>
    </v-row>

    <v-row >
      <v-col>
        <v-data-table
          :headers="headers"
          :items="operatorGroups"
          :options.sync="options"
          :server-items-length="total"
        >
          <template #item.name="{ item }">
            <router-link :to="{ name: 'operator-group.detail', params: { id: item._id } }">
              {{ item.name }}
            </router-link> {{ item.isNetworkOperator ? ' (Network)' : '' }}
          </template>
          <template #item.operators="{ item }">
            <v-chip class="ml-2 mb-3" dark :color="item.role === 'CPO' ? 'red' : 'blue'" v-for="operatorId in item.operators" :key="operatorId">
              {{ operatorId }}
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>



  </div>
</template>

<script>
import OperatorGroupRepository from "@repository/OperatorGroupRepository";

export default {

  data() {
    return {
      filters: {
        searchText: null,
        role: null,
      },
      total: 0,
      operatorGroups: [],
      options: null,
    }
  },

  mounted() {

  },

  watch: {
    filters: {
      handler() {
        if(this.options.page > 1) {
          this.options.page = 1
        } else {
          this.loadData()
        }
      },
      deep: true
    },
    options: {
      handler() {
        this.loadData()
      },
      deep: true
    },
  },

  computed: {
    headers() {
      return [
        { text: this.$t('account-number'), value: 'accountNumber' },
        { text: this.$t('role'), value: 'role' },
        { text: this.$t('name'), value: 'name', align: 'left' },
        { text: this.$t('company'), value: 'companyName', align: 'left', width: 300},
        { text: this.$tc('operator.operator', 2), value: 'operators', width: 600 },
      ]
    }
  },

  methods: {
    loadData() {
      this.showLoading(true)
      let data = {
        ...this.filters,
      }
      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage


      if (this.options.sortBy.length > 0) {
        data.sortBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.sortOrder = this.options.sortDesc[0] ? 'desc' : 'asc'
      }
      OperatorGroupRepository.search(data).then(operators => {
        this.total = operators.total
        this.operatorGroups = operators.items
        this.showLoading(false)
      }).catch(err => {
        this.notifyError(err)
        this.showLoading(false)
      })
    }
  }
}
</script>

<style lang="scss">

</style>
