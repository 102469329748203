import Vue from 'vue'

class LocationRepository {

  controller = null
  controllerAddress = null

  get(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/location/get/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
  getByIdentifier(identifier) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/location/by-identifier/' + identifier,
        method: 'get',
      })
          .then(response => {
            resolve(response.data)
          }).catch(err => {
        reject(err)
      })
    })
  }
  create(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/location',
        method: 'post',
        data
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  update(id, data) {
 return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/location/' + id,
        method: 'post',
        data
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  search(data) {
    return new Promise((resolve, reject) => {

      if(this.controller) {
        this.controller.abort()
      }
      this.controller = new AbortController()

      Vue.auth.fetch({
        url: '/api/location/search',
        method: 'post',
        data,
        signal: this.controller.signal,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  searchAddress(query, sessionToken) {
    return new Promise((resolve, reject) => {


      if(this.controllerAddress) {
        this.controllerAddress.abort()
      }
      this.controllerAddress = new AbortController()

      Vue.auth.fetch({
        url: '/api/shared/address/search',
        method: 'post',
        data: {
          query,
          sessionToken,
        },
        signal: this.controllerAddress.signal,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getPlace(placeId, sessionToken) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/shared/address/place',
        method: 'post',
        data: {
          placeId,
          sessionToken,
        }
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  usage(locationId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point/location/' +locationId+ '/stats',
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}


export default new LocationRepository()
