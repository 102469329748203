<template>
  <div v-if="detail">
    <v-row dense v-if="!hideCountry">
      <template v-if="!readonly && canSetAllCountries">
        <v-col cols="auto">
          <v-checkbox
              :label="$t('billing-details.all-countries')"
              v-model="allCountries"
              :readonly="readonly"
              :disabled="disabled"
              hide-details
          />
        </v-col>
      </template>
      <v-spacer v-if="!readonly && canSetAllCountries"></v-spacer>
      <v-col v-if="!allCountries">
        <available-select
            type="country"
            :label="$t('country')"
            v-model="detail.country"
            hide-details
            :readonly="readonly || allCountries"
            :disabled="disabled"
            :placeholder="$t('select')"
            persistent-placeholder
            :filter="filterCountry"
            :rules="countryRules"

        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-text-field
            :label="$t('name')"
            v-model="detail.name"
            :rules="[commonRules.required]"
            :readonly="readonly"
            :disabled="disabled"
            hide-details
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-text-field
            :label="$t('billing-details.legal-form')"
            v-model="detail.legalForm"
            :readonly="readonly"
            :disabled="disabled"
            :clearable="!disabled && !readonly"
            hide-details
        />
      </v-col>
      <v-col>
        <v-text-field
            :label="$t('billing-details.capital-amount')"
            v-model.number="detail.capitalAmount"
            :readonly="readonly"
            :disabled="disabled"
            type="number"
            :clearable="!disabled && !readonly"
            hide-details
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-text-field
            :label="$t('organization.registration-number')"
            v-model="detail.registrationNumber"
            :readonly="readonly"
            :disabled="disabled"
            hide-details
        />
      </v-col>
      <v-col>
        <v-text-field
            :label="$t('vat-number')"
            v-model="detail.vatNumber"
            :readonly="readonly"
            :disabled="disabled"
            hide-details
        >
          <template #append>
            <v-tooltip bottom v-if="detail.verifiedByVies">
              <template #activator="{on, attrs}">
                <v-icon color="success" v-on="on" v-bind="attrs">mdi-check-decagram</v-icon>
              </template>
              <div class="text-center">
                {{ $t('organization.verified-by-vies') }}
              </div>
            </v-tooltip>
            <v-tooltip bottom  v-else>
              <template #activator="{on, attrs}">
                <v-icon color="red" v-on="on" v-bind="attrs">mdi-alert-decagram</v-icon>
              </template>
              <div class="text-center">
                {{ $t('organization.vat-number-invalid') }}
              </div>
            </v-tooltip>

          </template>
        </v-text-field>
      </v-col>

    </v-row>

    <v-row dense>
      <v-col cols="2">
        <v-text-field
            hide-details
            :label="$t('number')"
            v-model="detail.address.number"
            :readonly="readonly"
            :disabled="disabled"
        />
      </v-col>
      <v-col>
        <v-text-field
            hide-details
            :label="$t('street')"
            v-model="detail.address.street"
            :readonly="readonly"
            :disabled="disabled"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-text-field
            hide-details
            :label="$t('zipcode')"
            v-model="detail.address.zipcode"
            :readonly="readonly"
            :disabled="disabled"
        />
      </v-col>
      <v-col>
        <v-text-field
            hide-details
            :label="$t('city')"
            v-model="detail.address.city"
            :readonly="readonly"
            :disabled="disabled"
        />
      </v-col>
      <v-col>
        <available-select
            hide-details
            type="country"
            :label="$t('country')"
            v-model="detail.address.country"
            :readonly="readonly"
            :disabled="disabled"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-text-field
            hide-details
            :label="$t('billing-details.contact-name')"
            v-model="detail.contactName"
            :readonly="readonly"
            :disabled="disabled"
        />
      </v-col>
      <v-col>
        <v-text-field
            hide-details
            :label="$t('billing-details.contact-email')"
            v-model="detail.contactEmail"
            :readonly="readonly"
            :disabled="disabled"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AvailableSelect from "@blocks/Select/AvailableSelect.vue";
import RulesMixin from "@mixins/RulesMixin";

export default {
  components: {
    AvailableSelect
  },

  mixins: [
    RulesMixin,
  ],

  data() {
    return {
      detail: this.value,
      allCountries: this.value?.country === null,
    }
  },

  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    value: Object,
    readonly: {type: Boolean, default: true},
    disabled: {type: Boolean, default: false},
    canSetAllCountries: Boolean,
    filterCountry: Function,
    hideCountry: Boolean,
  },

  mounted() {

  },

  computed: {
    countryRules() {
      if(!this.allCountries) {
        return [this.commonRules.required]
      }
      return []
    }
  },

  watch: {
    detail: {
      handler() {
        this.$emit('input', this.detail)
      },
      deep: true
    },

    value(val) {
      this.detail = val
    },

    allCountries(val) {
      if(val) {
        this.$set(this.detail, 'country', null)
      } else {
        this.$set(this.detail, 'country', '')
      }
    },
  },
}
</script>

<style lang="scss">

</style>
