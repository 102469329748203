import Vue from 'vue'

class OperatorGroupRepository {

  get(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/roaming/operator-group/get/' + id,
        method: 'get',
      })
          .then(response => {
            resolve(response.data)
          }).catch(err => {
        reject(err)
      })
    })
  }

  create(operator) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/roaming/operator-group',
        method: 'post',
        data: operator,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  update(id, operator) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/roaming/operator-group/' + id,
        method: 'put',
        data: operator,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  search(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/roaming/operator-group/search',
        method: 'post',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  updateBillingDetails(id, billingDetails) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/roaming/operator-group/update-billing-details/' + id,
        method: 'post',
        data: {billingDetails},
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  updateOperators(id, operators) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/roaming/operator-group/update-operators/' + id,
        method: 'post',
        data: {operators},
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default new OperatorGroupRepository()
