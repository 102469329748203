<script>
export default {

  data() {
    return {
      prevRoute: null,
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      const {name, params} = from
      vm.prevRoute = {name, params}
    })
  },
}
</script>
