<template>
  <div class="operator-group-detail pa-10 pt-0" v-if="operatorGroup">

    <tab-navigation
        :title="$tc('operator-group.operator-group')"
        icon="mdi-group"
        :breadcrumbs="[
          {text: $tc('operator-group.operator-group', 2), to: {name: 'operator-groups'}, exact: true },
         {text: operatorGroup.name },
      ]"
    >
      <v-tabs>
        <v-tab :to="{name: 'operator-group.detail'}" exact>{{ $t('informations') }}</v-tab>
        <v-tab disabled>{{ $tc('agreement.agreement', 2) }}</v-tab>
        <v-tab disabled>{{ $tc('invoice.invoice', 2) }}</v-tab>
      </v-tabs>
    </tab-navigation>


    <router-view></router-view>

  </div>
</template>

<script>


import LocationRepository from "@repository/LocationRepository";
import PrevRouteMixin from "@mixins/PrevRouteMixin.vue";
import TabNavigation from "@blocks/TabNavigation.vue";
import Tools from "@includes/tools";
import OperatorGroupRepository from "@repository/OperatorGroupRepository";


export default {
  components: {TabNavigation},

  data() {
    return {
      operatorGroup: null,
    }
  },

  mixins: [
    PrevRouteMixin
  ],

  mounted() {
    this.loadData()
  },

  watch: {

  },

  computed: {

  },

  methods: {
    loadData() {
      return new Promise((resolve, reject) => {
        this.showLoading(true)
        OperatorGroupRepository.get(this.$route.params.id).then(operatorGroup => {
          this.operatorGroup = operatorGroup
          this.showLoading(false)
          resolve(operatorGroup)
        })
        .catch(err => {
          this.showLoading(false)
          this.notifyError(err)
          reject(err)
        })
      })
    },
  }
}
</script>

<style lang="scss">

</style>
