<template>
  <div class="tab-nav">
    <v-breadcrumbs
        class="pl-0"
        :items="breadcrumbs"
        divider=">"
    ></v-breadcrumbs>
    <div class="d-flex pb-8">
      <div class="nav-title tab-title-wrapper">
        <div class="tab-title">
          <v-icon color="white" left>{{ icon }}</v-icon>
          {{ title }}
        </div>
      </div>
      <div class="nav-tabs">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    icon: String,
    breadcrumbs: Array,
  },

  mounted() {
    this.$nextTick(() => {
      this.onResize()
    })
    window.addEventListener('resize', this.onResize)
  },

  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onResize() {
      setTimeout(() => {
        let titleWidth = this.$el.querySelector('.nav-title').clientWidth
        this.$el.querySelector('.nav-tabs').style.width = (this.$el.clientWidth - titleWidth) + 'px'
      }, 200)
    }
  }
}
</script>


<style lang="scss">
  .tab-nav {
    .tab-title-wrapper {
      display: flex;
      padding-right: 30px;
      background-color: white;
      overflow-y: clip;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        width: 45px;
        background-color:  var(--v-primary-base);
        display: inline-block;
        transform: rotate(-45deg);
      }
      .tab-title {
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: 500;
        display: flex;
        z-index: 1;
        justify-content: center;
        align-items: center;
        padding: 0 12px;
        background-color:  var(--v-primary-base);
        color: white;
        white-space: nowrap;
      }
    }
    .v-tab {
      padding: 0 10px;
    }
  }
</style>
