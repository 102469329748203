<template>
  <div class="pa-10" v-if="operator">
    <title-page icon="ico-charging-point">
      {{ $tc("operator.operator") }}
    </title-page>

    <v-row justify="center">
      <v-col cols="6">
        <headline>{{ operator.name }}</headline>
        <div class="grid">

          <v-form class="form-grid" @submit.prevent="">
            <v-row>

              <v-col class="label">
                {{ $t('identifier') }}
              </v-col>
              <v-col>
                {{ operator.id }}
              </v-col>
            </v-row>

            <v-row>
              <v-col class="label">
                {{ $t('country-code') }}
              </v-col>
              <v-col>
                {{ operator.countryCode }}
              </v-col>
            </v-row>

            <v-row>
              <v-col class="label">
                {{ $t('party-id') }}
              </v-col>
              <v-col>
                {{ operator.partyId }}
              </v-col>
            </v-row>

            <v-row>
              <v-col class="label">
                {{ $t('name') }}
              </v-col>
              <v-col>
                <v-text-field v-model="operator.name" @change="save"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="label">
                {{ $t('operator.is-cpo') }}
              </v-col>
              <v-col>
                <v-switch v-model="operator.isCpo"  @change="save"></v-switch>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="label">
                {{ $t('operator.is-emp') }}
              </v-col>
              <v-col>
                <v-switch v-model="operator.isEmp"  @change="save"></v-switch>
              </v-col>
            </v-row>



            <v-row>
              <v-col class="label">
                {{ $t('operator.has-sessions') }}
              </v-col>
              <v-col>
                <v-switch v-model="operator.hasSessions"  @change="save"></v-switch>
              </v-col>
            </v-row>
          </v-form>

        </div>
      </v-col>
    </v-row>


    <v-row justify="center" v-if="operator.ocpiRoles.length > 0">
      <v-col cols="12">
        <headline>{{ $t('operator.ocpi-roles') }}</headline>
        <div class="grid">

          <div class="ocpi-roles">
            <div v-for="role in operator.ocpiRoles" :key="role.id" class="ocpi-role">
              <v-row>
                <v-col class="label">
                  {{ $t('operator.role') }}
                </v-col>
                <v-col>
                  {{ role.role }}
                </v-col>
                <v-col class="label">
                  {{ $t('operator.client-operator-id') }}
                </v-col>
                <v-col>
                  {{ role.clientOperator }}
                </v-col>
              </v-row>

              <v-row>
                <v-col class="label">
                  {{ $t('operator.registered') }}
                </v-col>
                <v-col cols="9">
                  {{ role.registered ? $t('yes') : $t('no') }}
                </v-col>


              </v-row>


              <v-row justify="center">
                <v-col cols="2" class="text-center">
                  Client
                </v-col>
              </v-row>

              <v-row>
                <v-col class="label">
                  {{ $t('operator.client-endpoint') }}
                </v-col>
                <v-col cols="9">
                  {{ role.senderEndpoint }}
                </v-col>
              </v-row>

              <v-row>
                <v-col class="label">
                  {{ $t('operator.client-version') }}
                </v-col>
                <v-col cols="9">
                  {{ role.senderVersion }}
                </v-col>
              </v-row>

              <v-row>
                <v-col class="label">
                  {{ $t('operator.client-enabled-modules') }}
                </v-col>
                <v-col cols="9">
                  {{ role.senderModules ? role.senderModules.map(m => m.identifier).join(', ') : '' }}
                </v-col>
              </v-row>


              <v-row justify="center">
                <v-col cols="2" class="text-center">
                  Server
                </v-col>
              </v-row>

              <v-row>
                <v-col class="label">
                  {{ $t('operator.server-endpoint') }}
                </v-col>
                <v-col cols="9">
                  {{ role.receiverEndpoint }}
                </v-col>
              </v-row>

              <v-row>
                <v-col class="label">
                  {{ $t('operator.server-enabled-modules') }}
                </v-col>
                <v-col cols="9">
                  {{ role.receiverModules ? role.receiverModules.join(', ') : '' }}
                </v-col>
              </v-row>

<!--              <v-row justify="center">-->
<!--                <v-col cols="2" class="text-center">-->
<!--                  <v-icon left>mdi-handshake</v-icon>-->
<!--                  Contrats-->
<!--                </v-col>-->
<!--              </v-row>-->

<!--              <v-row class="ocpi-role-contracts" justify="center">-->

<!--                <v-col cols="4" v-if="role.contracts.length === 0"  class="ocpi-role-contract">-->
<!--                  No contract-->
<!--                </v-col>-->
<!--                <v-col cols="4" v-for="contract in role.contracts" :key="contract.id" class="ocpi-role-contract">-->
<!--                  <v-row>-->
<!--                    <v-col>-->
<!--                      {{ contract.operator.name }} ({{ contract.operator.id }})-->
<!--                    </v-col>-->
<!--                  </v-row>-->
<!--                  <v-row>-->
<!--                    <v-col>-->
<!--                      <v-btn  v-for="tariff in contract.tariffs" :key="tariff.tariffId"  @click="openTariff(tariff.tariffId)">-->
<!--                        Tariff {{ tariff.filter ? tariff.filter : 'global' }}-->
<!--                      </v-btn>-->
<!--                    </v-col>-->
<!--                  </v-row>-->

<!--                </v-col>-->
<!--              </v-row>-->

            </div>
          </div>




        </div>
      </v-col>
    </v-row>


    <tariff-modal
        ref="tariffModal"
        :tariffId="selectedTariffId"
        readonly
    />


  </div>
</template>

<script>
import OperatorRepository from "@repository/OperatorRepository";
import TariffModal from "@/components/modals/TariffModal";

export default {
  components: {TariffModal},
  data() {
    return {
      operator: null,
      selectedTariffId: null,
    }
  },

  mounted() {
    this.loadData()
  },

  methods: {
    loadData() {
      OperatorRepository.get(this.$route.params.id).then(operator => this.operator = operator)
    },

    save() {
      if(this.operator.name) {
        OperatorRepository.update(this.operator.id, {
          name: this.operator.name,
          isEmp: this.operator.isEmp,
          isCpo: this.operator.isCpo,
          hasSessions: this.operator.hasSessions,
        })
      }
    },
  }
}
</script>

<style lang="scss">
.ocpi-roles {
    .ocpi-role {
      border: 1px solid #ccc;
      border-radius: 10px;
      padding: 15px 30px;
      box-shadow: 6px 6px 20px 0 #ccc;
      & + .ocpi-role {
        margin-top: 40px;
      }
      .ocpi-role-contract {
        display: block !important;
        border: 1px solid #ececec;
        border-radius: 10px;
        padding:  10px 30px;
        text-align: center;
        margin: 20px;
        .col {
          justify-content: center;
        }
        & + .ocpi-role-contract {
          margin: 0px 40px;
        }
      }
    }
}
</style>
